// ------------ colors -----------
$PRIMARY_COLOR: #2a2d33;
$WHITE_COLOR: #ffffff;
$BLUE_COLOR: #4285f6;
$DARK_COLOR: #202226;
$NAVBAR_BG_COLOR: #2a2d33;
$NEWSLETTER_BG_COLOR: #3b3f47;
$HR_COLOR: #5E5E5E;

// ------------ font sizes -----------
$BIG_HEADING_FONT_SIZE: 68px;
$TITLE_1_FONT_SIZE: 43px;
$TITLE_2_FONT_SIZE: 32px;
$TITLE_3_FONT_SIZE: 24px;
$BODY_TEXT_FONT_SIZE: 18px;
$SUBTITLE_FONT_SIZE: 14px;

// ------------ font weights -----------
$FONT_WEIGHT_REGULAR: 400;
$FONT_WEIGHT_MEDIUM: 500;
$FONT_WEIGHT_BOLD: bold;

// ------------ text opacity -----------
$HEADING_OPACITY: 1;
$BODY_TEXT_OPACITY: 0.6;
$PLACEHOLDER_OPACITY: 0.38;

// -------- miscellaneous --------
$FONT_FAMILY: "Noto Sans JP";
$NAVBAR_HEIGHT: 72px;
$BORDER_RADIUS: 4px;
$BOX_SHADOW: 0px 4px 8px rgba(0, 0, 0, 0.4);
