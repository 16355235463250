@import "../styles/variable.scss";

// ------- layout css -------
.layout {
  max-width: 1920px;
  margin: auto;
}

.layout-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  min-height: calc(80vh - 20px);
}

// ------- custom navbar css -------
.nav-menu {
  background-color: $NEWSLETTER_BG_COLOR;
}
.nav-clear {
  background-color: transparent;
}
.nav-solid {
  background-color: $NAVBAR_BG_COLOR;
  box-shadow: $BOX_SHADOW;
  transition: background-color 200ms linear;
}
.navbar {
  color: $WHITE_COLOR;
  padding: 0 11.5%;
  min-height: $NAVBAR_HEIGHT;
  .links {
    text-decoration: none !important;
    color: $WHITE_COLOR !important;
    font-family: $FONT_FAMILY;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
  }
  .navbar-toggler {
    border: none;
    padding: 0;
    .navbar-toggler-icon {
      width: 32px;
      height: 32px;
      background-image: url("../../assets/images/cross.svg") !important;
    }
  }
  .collapsed {
    .navbar-toggler-icon {
      background-image: url("../../assets/images/hamburger.svg") !important;
      width: 40px;
      height: 40px;
    }
  }
  .button-class {
    margin-left: 60px;
    @media screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 12px 14px;
    min-height: 64px;
    .navbar-brand {
      padding: 0;
      img {
        height: 40px;
      }
    }
    .form-inline,
    .pc-consult {
      display: none;
    }
    .menu-items-wrapper {
      text-align: center;
      padding-top: 38px;
      padding-bottom: 32px;
      .btn-button-outline {
        border: 2px solid $WHITE_COLOR;
        background: transparent;
        width: 100%;
        max-width: 480px;
        color: $WHITE_COLOR !important;
        align-items: center;
        padding: 10px 20px;
      }
      hr {
        margin: 20px 0;
        border: 1px solid $HR_COLOR;
        opacity: 0.2;
      }
    }
  }
}

@keyframes expand-pc-nav {
  0% {
    height: 0px;
  }
  100% {
    height: height;
  }
}

@media screen and (min-width: 768px) {
  .nav-menu {
    transition: all 0.6s ease-in;
  }
  .navbar-toggler {
    position: absolute;
    right: 11.5%;
  }
  .menu-items-wrapper {
    display: none;
  }
  .collapsing {
    position: absolute;
  }
  .pc-consult {
    margin-right: 116px;
  }
  .pc-nav {
    position: absolute;
    top: 70px;
    left: 0;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    background-color: $NEWSLETTER_BG_COLOR;
    z-index: 1030;
    animation: expand-pc-nav 0.6s ease-in-out;
    -webkit-animation: expand-pc-nav 0.6s ease-in-out;
    .nav-container {
      width: 800px;
      position: absolute;
      left: 50%;
      top: 50%;
      text-align: center;
      font-size: 32px;
      line-height: 150%;
      transform: translate(-50%, -60%);
      .active {
        border-bottom: 2px solid $BLUE_COLOR;
      }
      .service-tag {
        font-size: 18px;
        line-height: 150%;
        color: $WHITE_COLOR;
        opacity: 0.6;
        margin-bottom: 8px;
      }
      .sports-menu {
        width: 500px;
        display: inline-flex;
        justify-content: space-between;
      }
      hr {
        border: 1px solid $HR_COLOR;
        margin: 32px 0;
      }
      a {
        color: $WHITE_COLOR !important;
        text-decoration: none !important;
      }
    }
  }
}

// ------- custom footer css -------
.footer {
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: $PRIMARY_COLOR;
  min-height: 105px;
  color: $WHITE_COLOR;
  padding: 0 11.5%;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);

  .footer-row {
    width: 100%;
    margin: 0;
    .col-sm-4 {
      margin: auto;
      padding: 0;
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
      .footer-icon {
        margin: auto 22.5px;
        cursor: pointer;
      }
      .footer-links {
        font-family: $FONT_FAMILY;
        font-style: normal;
        font-weight: normal;
        color: $WHITE_COLOR;
        text-decoration: none;
        padding-left: 60px;
        font-size: 18px;
        &:first-child {
          padding: 0;
        }
      }
      hr {
        display: none;
        border: 1px solid #2e364d !important;
      }
    }
  }

  @media screen and (max-width: 576px) {
    padding: 0 14px;
    .footer-row {
      flex-wrap: wrap-reverse;
      hr {
        display: none;
      }
      .col-sm-4 {
        text-align: center;
        &:first-child {
          text-align: center;
          margin: 44px 0;
        }
        &:last-child {
          text-align: center;
        }
        .footer-icon {
          margin: 16px 30px 0;
        }
        .footer-links {
          display: block;
          padding: 0;
          margin: 16px 0;
          opacity: 0.87;
        }
        hr {
          display: block;
        }
      }
    }
  }
}

// ------- custom button css -------
.button-class {
  font-family: $FONT_FAMILY;
  padding: 12px 20px;
  width: auto;
  height: 51px;
  background: #4285f6;
  border: none;
  border-radius: $BORDER_RADIUS;
  font-style: normal;
  font-weight: 500;
  font-size: $BODY_TEXT_FONT_SIZE;
  line-height: 150%;
  text-align: center;
  color: #0e1630 !important;
  box-shadow: none !important;
  svg {
    margin-top: -3px;
    margin-left: 4px;
  }
  @media screen and (max-width: 600px) {
    padding: 10px 20px;
    svg {
      margin-top: -2px;
    }
  }
  &:hover {
    background: #80afff;
    color: #0e1630 !important;
  }
  &:disabled {
    cursor: not-allowed;
  }
}

// ------- custom heading css -------
.heading-common {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: bold;
  line-height: 150%;
  opacity: $HEADING_OPACITY;
}

.heading-68 {
  @extend .heading-common;
  font-size: 68px;
}

.heading-default {
  @extend .heading-common;
  font-size: 43px;
}

// ------- custom title css -------
.custom-title-48 {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_BOLD;
  font-size: 48px;
  line-height: 130%;
  color: $WHITE_COLOR;
}

.custom-title-43 {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_BOLD;
  font-size: $TITLE_1_FONT_SIZE;
  line-height: 150%;
  color: $WHITE_COLOR;
}

.custom-title-32 {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_BOLD;
  font-size: $TITLE_2_FONT_SIZE;
  line-height: 150%;
  color: $WHITE_COLOR;
}

.custom-title-24 {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: $FONT_WEIGHT_MEDIUM;
  font-size: $TITLE_3_FONT_SIZE;
  line-height: 150%;
  color: $WHITE_COLOR;
}

.custom-title-default {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  color: $WHITE_COLOR;
}

// ------- custom text css -------
.custom-text-common {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  opacity: $BODY_TEXT_OPACITY;
}

.custom-text-22 {
  @extend .custom-text-common;
  font-size: 22px;
}

.custom-text-18 {
  @extend .custom-text-common;
  font-size: $BODY_TEXT_FONT_SIZE;
}

.custom-text-16 {
  @extend .custom-text-common;
  font-size: 16px;
}

.custom-text-14 {
  @extend .custom-text-common;
  font-size: $SUBTITLE_FONT_SIZE;
}

.custom-text-12 {
  @extend .custom-text-common;
  font-size: $SUBTITLE_FONT_SIZE;
}

.custom-text-default {
  @extend .custom-text-common;
  font-size: $SUBTITLE_FONT_SIZE;
}

.custom-text-3 {
  @extend .custom-text-common;
  font-family: $FONT_FAMILY;
  font-size: 16px;
}

.custom-text-4 {
  @extend .custom-text-common;
  font-family: $FONT_FAMILY;
  font-size: 12px;
}

// ------- custom input group css -------
::-webkit-input-placeholder {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 150%;
  opacity: 0.38 !important;
  color: #ffffff !important;
}

.form-control {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: normal;
  font-size: 18px !important;
  box-shadow: none !important;
  border: 1px solid #484e66;
  height: 51px;
  padding: 12px 20px;
  background-color: $DARK_COLOR;
  color: $WHITE_COLOR !important;
  opacity: 0.87;
}

.form-control:focus {
  background-color: #0e1017;
  color: $WHITE_COLOR !important;
  box-shadow: none !important;
  border-color: #484e66;
}

.custom-input-group {
  .button-class {
    height: auto;
    width: 180px;
    z-index: 3;
    @media screen and (max-width: 600px) {
      width: auto;
    }
  }
}

// ------- custom form group css -------
.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-family: $FONT_FAMILY;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  opacity: 0.6;
  color: $WHITE_COLOR;
  margin-bottom: 8px;
}

// ------- custom card css -------
.card {
  background-color: $NEWSLETTER_BG_COLOR;
  color: $WHITE_COLOR;
  border: 1px solid #4e5564;
  .card-img-top {
    height: 226px;
    object-fit: cover;
  }
  .card-body {
    padding: 20px 12px;
    .card-title {
      height: 60px;
      overflow: hidden;
      margin-bottom: 8px;
    }
    .card-text-wrapper {
      max-width: 100%;
      margin-bottom: 20px;
      .custom-text-18 {
        display: -webkit-inline-box;
        max-height: 80px;
        line-height: 150%;
        overflow: hidden;
        word-break: break-word;
        -webkit-box-orient: horizontal;
        p {
          font-family: $FONT_FAMILY;
          font-style: normal;
          font-weight: normal;
          font-size: $BODY_TEXT_FONT_SIZE;
          line-height: 150%;
          opacity: $BODY_TEXT_OPACITY;
          font-weight: normal;
          margin-bottom: 0;
          strong {
            display: inline;
            font-weight: normal;
          }
        }
      }
    }
    .card-text {
      font-family: $FONT_FAMILY;
      font-style: normal;
      font-weight: $FONT_WEIGHT_MEDIUM;
      font-size: 14px;
      float: right;
      color: $BLUE_COLOR;
    }
    .date-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-subtitle {
        display: inline;
        font-family: $FONT_FAMILY;
        font-style: normal;
        font-weight: normal;
        font-size: $SUBTITLE_FONT_SIZE;
        line-height: 150%;
        color: $WHITE_COLOR;
        opacity: $PLACEHOLDER_OPACITY;
      }
      .custom-text-14 {
        color: $BLUE_COLOR;
        font-weight: $FONT_WEIGHT_MEDIUM;
      }
      svg {
        color: $BLUE_COLOR;
        width: 20px;
        height: 20px;
        margin-top: -2px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    max-width: 100%;
    .card-body {
      .card-title {
        height: auto;
        .custom-title-2 {
          font-size: 18px;
        }
      }
      .card-text-wrapper {
        margin-bottom: 16px;
        .custom-text-18 {
          max-height: 85px;
          margin-bottom: 0;
          p {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}

// ------- custom spinner css -------
.spinner {
  margin: auto;
  width: 60px;
  height: 60px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

.cube1,
.cube2 {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: $BLUE_COLOR;
  opacity: 0.87;

  -webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
  animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
  25% {
    -webkit-transform: translateX(50px) rotate(-90deg) scale(0.5);
  }
  50% {
    -webkit-transform: translateX(50px) translateY(50px) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0px) translateY(50px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes sk-cubemove {
  25% {
    transform: translateX(50px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(50px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(50px) translateY(50px) rotate(-179deg);
    -webkit-transform: translateX(50px) translateY(50px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(50px) translateY(50px) rotate(-180deg);
    -webkit-transform: translateX(50px) translateY(50px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(50px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(50px) rotate(-270deg)
      scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
