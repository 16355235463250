@import "../../common/styles/variable.scss";

.common-about--team {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  width: 100%;
  opacity: 0.9;
  backdrop-filter: blur(6px);
  color: $WHITE_COLOR;
}

// ------- home hero section css -------
.hero-section {
  text-align: left;
  height: 100vh;
  max-height: 1080px;
  margin-top: -72px;

  .hero-bg-image-wrapper {
    position: relative;
  }

  .hero-content {
    z-index: 1;
    padding: 0;
    padding-left: 11.5%;
    max-height: 1080px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));

    .heading-wrapper {
      margin-bottom: 32px;
      font-weight: bold;

      .select-color {
        color: #4285f6;
        line-height: 150%;
        margin-bottom: 0;
        &:before {
          color: $WHITE_COLOR;
          content: "Powerful ";
        }
      }
      .heading-mobile {
        display: none;
      }
    }
    .text-wrapper {
      margin-bottom: 48px;
      .custom-title-24 {
        opacity: 0.87;
      }
    }
    .button-class {
      width: 280px;
    }
  }

  @media screen and (min-width: 600px) and (max-height: 1080px) {
    .hero-content {
      position: absolute;
      bottom: 0;
      margin-bottom: 100px;
    }
  }

  @media screen and (min-width: 600px) {
    min-height: 800px;
    .hero-content {
      position: absolute;
      margin-top: 620px;
      .heading-margin {
        margin-top: -12px;
      }
    }
    .hero-bg-image-wrapper {
      .rectangle-mobile {
        display: none;
      }
      .rectangle {
        width: 100%;
        height: 100vh;
        min-height: 800px;
        max-height: 1080px;
        position: absolute;
        top: 0;
        left: 0;
      }

      .rectangle-2 {
        background-image: url("../../assets/images/home-hero.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
      }
      .rectangle-3 {
        background: rgba(0, 0, 0, 0.1);
        mix-blend-mode: hard-light;
        opacity: 0.6;
      }
      .rectangle-4 {
        background: #202226;
        mix-blend-mode: hard-light;
      }
    }
  }

  @media screen and (max-width: 600px) {
    height: 98vh;
    .rectangle-2 {
      position: absolute;
      z-index: 0;
      width: 100%;
      margin-top: 42vh;
      height: 56vh;
      background-image: url("../../assets/images/home-hero-mobile.webp");
      background-size: cover;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
      background-blend-mode: hard-light;
    }
    .rectangle-3,
    .rectangle-4 {
      display: none;
    }
    .hero-content {
      height: 56vh;
      min-height: 420px;
      padding: 40px 14px 0;
      text-align: center;
      margin-top: $NAVBAR_HEIGHT;
      background: linear-gradient(
        180deg,
        #2a2d33 86.24%,
        rgba(42, 45, 51, 0) 100%
      );
      .heading-wrapper {
        text-transform: capitalize;
        margin-bottom: 16px !important;
        .heading-68 {
          font-size: 32px;
          display: inline;
        }
        margin-bottom: 20px;
        .heading-mobile {
          display: block;
          font-size: 28px;
        }
      }
      .text-wrapper {
        margin-bottom: 32px;
        padding: 0;
        .custom-title-24 {
          font-size: 18px;
          font-weight: $FONT_WEIGHT_REGULAR;
          opacity: $BODY_TEXT_OPACITY;
        }
      }
    }
  }
}

// ------- about us section css -------
.about-us {
  background: linear-gradient(rgba(23, 27, 38, 0.9), rgba(23, 27, 38, 0.9)),
    url("../../assets/images/about_us.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-blend-mode: darken;
  padding: 240px 14px;
  @extend .common-about--team;

  .about-title-wrapper {
    margin-bottom: 24px;
  }
  .about-text-wrapper {
    margin: auto;
  }
  @media screen and (min-width: 600px) {
    .about-text-wrapper {
      text-align: left;
      max-width: 700px;
    }
  }
  @media screen and (max-width: 600px) {
    height: calc(100vh - 64px);
    padding: 0 14px;
    .custom-title-43 {
      font-size: 28px !important;
      font-weight: 700 !important;
    }
    .about-text-wrapper {
      text-align: center;
      .custom-text-22 {
        font-size: 18px !important;
        font-weight: 400 !important;
      }
    }
  }
}

// ------- our services section css -------
.services {
  .col-md-6,
  .col-12 {
    padding: 0;
  }
  .right-image-row {
    margin: 0;
    padding-bottom: 180px;
    .col {
      margin: auto;
      padding: 0;
    }
  }
  .left-image-row {
    margin: 0;
    background-color: $NEWSLETTER_BG_COLOR;
    .col {
      margin: auto;
      padding: 0;
    }
  }
  .title-wrapper {
    max-width: 445px;
  }
  img {
    width: 100%;
    padding: 0;
  }
  @media screen and (min-width: 600px) {
    padding: 209px 0 0;
    .custom-text-16 {
      padding: 0 11.5%;
    }
    .section-heading-wrapper {
      padding-left: 11.5%;
      margin-bottom: 80px;
    }
    .right-image-row {
      .col-left {
        margin: auto 0;
        padding-left: 11.5%;
        padding-right: 6%;
        max-width: 100%;
      }
      .col-right {
        margin-left: auto;
      }
    }
    .left-image-row {
      margin-bottom: 180px;
      .col-right {
        margin: auto 0;
        padding-right: 11.5%;
        padding-left: 6%;
        max-width: 100%;
      }
    }
    .title-wrapper {
      margin-bottom: 30px;
    }
    .text-wrapper {
      max-width: 100%;
      margin-bottom: $TITLE_1_FONT_SIZE;
    }
  }

  @media screen and (max-width: 600px) {
    padding: 40px 0 0;
    .custom-text-16 {
      padding: 0 15px;
    }
    .section-heading-wrapper {
      padding-left: 15px;
      margin-bottom: 40px;
      .custom-title-43 {
        font-size: 27px !important;
        font-weight: 700 !important;
      }
    }
    .right-image-row {
      display: flex;
      flex-flow: column-reverse;
      padding: 0;
    }
    .left-image-row {
      padding: 0;
    }
    .title-wrapper {
      padding: 0 15px;
      margin: 24px 0 12px 0;
      .custom-title-43 {
        font-size: 28px !important;
        font-weight: 700 !important;
      }
    }
    img {
      height: 280px;
      object-fit: cover;
    }
    .text-wrapper {
      padding: 0 15px;
      margin-bottom: 24px;
    }
    a {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 80px;
      padding: 15px;
      .btn {
        width: 138px;
        height: 40px;
        font-size: 12px;
      }
    }
  }
}

// ------- team section css -------
.team {
  @extend .common-about--team;
  background: linear-gradient(rgba(23, 27, 38, 0.9), rgba(23, 27, 38, 0.9)),
    url("../../assets/images/rectangle115.webp") no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  background-blend-mode: darken;
  padding: 205px 14px;

  .team-title-wrapper {
    margin-bottom: 24px;
  }
  @media screen and (min-width: 600px) {
    .team-text-wrapper {
      max-width: 920px;
      margin: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 600px) {
    height: calc(100vh - 64px);
    padding: 0 14px;
    .team-title-wrapper {
      .custom-title-43 {
        font-size: 28px;
        font-weight: 700;
      }
    }
    .team-text-wrapper {
      .custom-text-22 {
        font-weight: 400;
        font-size: 18px;
      }
    }
  }
}

// ------- subscribe section css -------
.subscribe-section {
  text-align: center;
  background-color: $NEWSLETTER_BG_COLOR;

  .form-control:focus {
    border-right: none;
  }

  .subscribe-title-wrapper {
    margin-bottom: 16px;
  }
  .custom-input-group {
    max-width: 540px;
    margin: 40px auto 0;
  }
  @media screen and (min-width: 600px) {
    padding: 80px 10px;
  }
  @media screen and (max-width: 600px) {
    padding: 40px 10px;
    .custom-title-43 {
      font-size: 24px;
      font-weight: 700;
    }
    .custom-text-18 {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

// ------- consult section css -------
.consult {
  background: url("../../assets/images/rectangle111.webp");
  background-size: 100% auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: right;

  .row {
    margin: 0;
    .custom-title-48 {
      margin-bottom: 20px;
    }
    .text-wrapper {
      max-width: 445px;
      margin-bottom: 60px;
    }
    .word-wrapper-mobile {
      display: none;
    }
    .col-sm-6 {
      padding: 60px 30px 60px 11.5%;
      background: linear-gradient(90deg, $PRIMARY_COLOR, $PRIMARY_COLOR);
      .form-wrapper {
        max-width: 445px;
        .button-wrapper {
          float: right;
          margin-top: 12px;
          .button-class {
            width: 164px;
          }
        }
      }
      .form-group {
        max-width: 445px;
      }
      .form-submit-success-wrapper {
        margin: auto;
        padding: 25% 0;
        text-align: center;
        .check-circle-icon {
          width: 80px;
          height: 80px;
          margin-bottom: 40px;
          color: #4285f6;
        }
        .submit-success-title {
          margin-bottom: 20px;
        }
        .submit-success-text {
          max-width: 445px;
          margin: auto;
        }
        @media screen and (min-width: 1200px) {
          padding: 35% 0;
          min-height: 723px;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    padding: 0;
    margin-bottom: 60px;
    .row {
      background: none;
      .word-wrapper {
        display: none;
      }
      .word-wrapper-mobile {
        display: block;
        background: url("../../assets/images/rectangle111.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        padding: 32px 14px;
        margin-bottom: 32px;
        .custom-title-32 {
          font-size: 28px;
          font-weight: 700;
        }
      }
      .text-wrapper {
        max-width: 284px;
        margin-bottom: 0;
        .custom-text-18 {
          font-size: 14px;
          opacity: 0.6;
        }
      }
      .col-sm-6 {
        padding: 0;
        .form-wrapper {
          max-width: 100%;
          padding: 0 14px;
          .button-wrapper {
            float: none;
            margin-top: 0;
            .button-class {
              width: 100%;
            }
          }
        }
        .form-group {
          max-width: 100%;
        }
        .form-submit-success-wrapper {
          padding: 60px 0;
          .check-circle-icon {
            width: 48px;
            height: 48px;
            margin-bottom: 24px;
          }
          .submit-success-title {
            margin-bottom: 12px;
            .custom-title-1 {
              font-size: 24px;
            }
          }
          .submit-success-text {
            padding: 0 14px;
            max-width: 100%;
            .custom-text-2 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

// ------- newsletter section css -------
.newsletter-hero {
  padding: 47px 30px 40px 11.5%;
  .custom-text-wrapper {
    max-width: 540px;
    margin-bottom: 24px;
  }
  @media screen and (min-width: 600px) {
    background: url("../../assets/images/rectangle55.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    mix-blend-mode: normal;
    backdrop-filter: blur(1px);
    .custom-title-wrapper {
      margin-bottom: 12px;
    }
  }
  @media screen and (max-width: 600px) {
    background: url("../../assets/images/rectangle55_1.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
    mix-blend-mode: normal;
    backdrop-filter: blur(1px);
    padding: 32px 14px;
    .custom-title-wrapper {
      margin-bottom: 8px;
      .custom-title-43 {
        font-size: 24px;
        font-weight: 700;
      }
    }
    .custom-text-wrapper {
      margin-bottom: 16px;
      max-width: 100%;
      .custom-text-18 {
        font-size: 14px;
        font-weight: 400;
      }
    }
    a {
      .btn-primary {
        width: 121px;
        height: 43px;
        padding: 8px 16px;
      }
    }
  }
}

// ------- post-list section css -------
.post-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 60px 11.5% 30px;
  .link:hover {
    text-decoration: none;
  }
  .card {
    margin-bottom: 30px;
    .thumbnail-contain {
      background-position: center center;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 41px 14px 31px;
    .row {
      margin-left: -14px;
      margin-right: -14px;
    }
    .card {
      margin: 0 auto 30px;
      .card-title {
        margin-bottom: 12px;
        .custom-title-default {
          font-size: 18px;
          font-weight: 700;
        }
      }
    }
  }
}

// ------- post css -------
.post {
  position: relative;
  .post-content {
    max-width: 48%;
    margin: 30px auto 150px;
    font-family: $FONT_FAMILY;
    font-style: normal;
    font-weight: normal;

    .post-title-wrapper {
      margin-bottom: 16px;
    }
    .thumbnail {
      width: 100%;
      margin: 48px 0;
    }
    p,
    li {
      line-height: 150%;
      font-size: 18px;
      color: #aaabad;
      img {
        opacity: 1;
        width: 80%;
        margin: 40px 0 32px;
      }
    }
    ul,
    ol {
      padding-left: 20px;
      margin-bottom: 42px;
    }
  }

  .promotion {
    position: sticky;
    position: -webkit-sticky;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    margin-top: -196px;
    margin-left: auto;
    margin-right: 11.5%;
    width: 100%;
    max-width: 165px;
    z-index: 2;
    .button-class {
      padding: 12px 10px;
      margin-top: 18px;
      height: auto;
    }
    hr {
      margin: 40px auto;
      opacity: 0.1;
      border: 1px solid $WHITE_COLOR;
    }
    .social-links-wrapper {
      text-align: center;
      display: flex;
      justify-content: space-between;
      opacity: $BODY_TEXT_OPACITY;
    }
  }

  .posts-title {
    padding-left: 11.5%;
  }
  .post-list {
    padding-top: 33px;
  }

  @media screen and (max-width: 1350px) and (min-width: 924px) {
    .post-content {
      width: 40%;
    }
  }
  @media screen and (max-height: 480px) {
    .promotion {
      top: 170px;
      transform: none;
    }
  }
  @media screen and (max-width: 600px) {
    .post-content {
      max-width: 100%;
      margin: 20px 0 80px;
      padding: 0 14px;
      .post-title-wrapper {
        margin-bottom: 12px;
        .custom-title-32 {
          font-size: 24px;
          margin-bottom: 12px;
          font-weight: 700;
        }
      }
      .custom-text-12 {
        font-size: 12px;
        font-weight: 400;
      }
      .thumbnail {
        margin: 24px 0;
      }
      p,
      li {
        img {
          margin: 32px 0;
          width: 100%;
        }
      }
    }
    .promotion {
      display: none;
    }
    .posts-title {
      padding: 0 14px;
      .custom-title-32 {
        font-size: 24px;
      }
    }
  }
}
