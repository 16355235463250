@import "./common/styles/variable.scss";

body {
  margin: 0;
  font-family: "Noto Sans JP", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $WHITE_COLOR;
  background-color: $PRIMARY_COLOR;
}

html {
  scroll-behavior: smooth;
}
